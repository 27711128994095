@import "./src/mypage/scss/tek-settings.scss";









































.tekna-magazine__article-body{
    a {
        @include media-breakpoint-down(md){
            flex-direction: column;
        }

        display: flex;
        text-decoration: none;

        h2:not(.featured-article-h2) {
          font-size: 1.7rem;
        }


        &:hover {
            color: black;
            h2 > .h2-last-word{
                &:after {
                    left: .5rem;
                }
            }
        }
    }

    p:first-of-type {
        @include media-breakpoint-down(sm){
            margin-bottom: .75rem;
        }
        font-size: 13px;
    }

    P:last-of-type {
        margin-bottom: 0;
    }
}


.tekna-magazine__article {


    &__large {
        
        .tekna-magazine__article-body{
            @include media-breakpoint-down(lg){
                padding-left: 1rem;
                padding-right: 1rem;
                padding-top: .5rem;
                padding-bottom: .5rem;
            }
            @include media-breakpoint-down(md){
                padding-left: 1rem;
                padding-right: 1rem;
                padding-top: 1rem;
                padding-bottom: 1.5rem;
            }
            @include media-breakpoint-down(sm){
                padding-left: .5rem;
                padding-right: .5rem;
                padding-top: .5rem;
                padding-bottom: 1.5rem;
            }
            border: 1px solid black;
            padding-top: 18px;
            padding-bottom: 18px;
            padding-right: 3.125rem;

            &__img {
                 @include media-breakpoint-down(lg){
                    margin-right: 0;
                }
                @include media-breakpoint-down(md){
                    margin-bottom: 1.5rem;
                }
                margin-right: 1.875rem;
                

                img {
                    @include media-breakpoint-down(lg){
                        width: 516px;
                        right: 24px;
                        
                    }
                    @include media-breakpoint-down(md){
                        width: 100%;
                        right:0;
                    }
                    position: relative;
                    right: 18px;
                    top: 0px;
                    width: 606px;
                    height: auto;
                    transition: all .3s ease;
                }
            }

            &__text {
                @include media-breakpoint-down(lg){
                    padding-top: .75rem;
                    padding-bottom: .75rem;
                    padding-left: 1.25rem;
                    padding-right: 1.25rem;
                }
                @include media-breakpoint-down(MD){
                    padding-top: .75rem;
                    padding-bottom: .75rem;
                    padding-left: 1.25rem;
                    padding-right: 1.25rem;
                }
                @include media-breakpoint-down(sm){
                    p:not(:first-of-type){
                        font-size: 15px;
                        line-height: 1.5rem;
                    }
                    padding-top: unset;
                    padding-bottom: unset;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;

                p:first-of-type {
                    @include media-breakpoint-up(lg){
                        margin-bottom: 1.75rem;
                    }
                }

                h2 {
                    @include media-breakpoint-down(sm){
                        margin-bottom: .75rem;
                    }
                    margin-bottom: 1.25rem;
                }
            }
        }

        &:nth-child(even){
            .tekna-magazine__article-body{
                @include media-breakpoint-down(lg){
                    padding-left: 1rem;
                    padding-right: 1rem;
                    padding-top: 1rem;
                    padding-bottom: 1.5rem;
                }
                @include media-breakpoint-down(md){
                    padding-left: 1rem;
                    padding-right: 1rem;
                    padding-top: 1rem;
                    padding-bottom: 1.5rem;
                }
                @include media-breakpoint-down(sm){
                    padding-left: .5rem;
                    padding-right: .5rem;
                    padding-top: .5rem;
                    padding-bottom: 1.5rem;
                }
                padding-left: 3.125rem;
                padding-right: 0;

                &__img {
                    @include media-breakpoint-down(lg){
                        margin-left: 0;
                    }
                    @include media-breakpoint-down(md){
                        order: 1;
                    }
                    margin-left: 1.875rem;
                    margin-right: 0;
                    order: 2;

                    img {
                        @include media-breakpoint-down(lg){
                            right: -8px;
                        }
                        @include media-breakpoint-down(md){
                            right: 0;
                        }
                        right: -18px;
                    }
                }

                &__text {
                    @include media-breakpoint-down(lg){
                        padding-right: 1.625rem;
                    }
                    @include media-breakpoint-down(lg){
                        padding-right: 1rem;
                    }
                    @include media-breakpoint-down(sm){
                        order: 2;
                    }
                    order: 1;
                }
            }

        }
    }

    &__small {
        .tekna-magazine__article-body{
            @include media-breakpoint-down(lg){
                padding-left: 3rem;
                padding-right: 3rem;
            }
            @include media-breakpoint-down(md){
                padding-left: 1rem;
                padding-right: 1rem;
            }
            @include media-breakpoint-down(sm){
                padding: 0;
            }
            a {
                flex-direction: column;
            }
            padding-left: 100px;
            padding-right: 100px;

            &__img {
                @include media-breakpoint-down(sm){
                        margin-bottom: 1rem;
                }
                margin-bottom: 1.25rem;
                
                img {
                    @include media-breakpoint-down(lg){
                        width: 100%;
                    }
                    width: 372px;
                    height: auto;
                }
            }

            &__text {
                h2 {
                    margin-bottom: .75rem;
                }
            }
        }
    }

}

.blockspacing {
    @include media-breakpoint-down(sm){
        margin-bottom: 40px;
    }
}

